import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '@cca-infra/core';
import {
  SystemSettingsNotificationServiceViewModel,
  UpdateSystemSettingsRequest,
} from '../model';
import { id } from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class NotificationSystemSettingsService extends BaseApiService {
  constructor() {
    super(`notification.v1.systemSettings`);
  }

  getNotificationSystemSettings(): Observable<SystemSettingsNotificationServiceViewModel> {
    return this.http.get<SystemSettingsNotificationServiceViewModel>(
      `${this.url}get-system-settings`,
    );
  }

  getNotificationSystemSettingsBySalesOrg(
    salesOrgId: id,
  ): Observable<SystemSettingsNotificationServiceViewModel> {
    return this.http.get<SystemSettingsNotificationServiceViewModel>(
      `${this.url}get-system-settings/${salesOrgId}`,
    );
  }

  updateNotificationSystemSettings(
    settings: UpdateSystemSettingsRequest,
  ): Observable<SystemSettingsNotificationServiceViewModel> {
    return this.http.post<SystemSettingsNotificationServiceViewModel>(
      `${this.url}update-system-settings`,
      settings,
    );
  }

  deleteNotificationSystemSettings(
    settings: UpdateSystemSettingsRequest,
  ): Observable<SystemSettingsNotificationServiceViewModel> {
    return this.http.post<SystemSettingsNotificationServiceViewModel>(
      `${this.url}delete-system-settings`,
      settings,
    );
  }
}
