/**
 * Notification Service
 * <p>The Notification Service is used to notify users via Email, Push and or SMS messages</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Date grouping enum  1 = Today  2 = Week  3 = Older
 */
export enum DateGroup {
  Today = 1,
  Week = 2,
  Older = 3,
}
