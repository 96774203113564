/**
 * Notification Service
 * <p>The Notification Service is used to notify users via Email, Push and or SMS messages</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = None  1 = Email  2 = MobilePushMessage  4 = WebPushMessage  8 = Webhook
 */
export enum NotificationEmitType {
  None = 0,
  Email = 1,
  MobilePushMessage = 2,
  WebPushMessage = 4,
  Webhook = 8,
}
